/**
 * Middleware для проверки авторизации пользователя
 */
export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server) return;
  const { $pinia } = useNuxtApp();
  const auth = useAuth($pinia);

  // если это публичная страница или если пользователь уже вошёл
  if (to.meta.isPublic || auth.isLoggedIn) return;

  if (auth.isRequiredToRefresh) {
    // TODO:!!! refresh auth
    // await auth.refreshToken();
    auth.logout();
    // if (auth.isLoggedIn) return;
    // TODO: add push сессия истекла
    await navigateTo(
      {
        path: '/auth/login',
        query: {
          expire: 1,
        },
      },
      {
        external: true,
      },
    );
    return;
  }

  // иначе кидаем на стартовый экран
  await navigateTo(
    {
      path: '/',
      query: {
        expire: 1,
      },
    },
    { external: true },
  );
});
